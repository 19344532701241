<template>
  <div>
    <float-label :isBottomAlign="label === 'Email' && !error ? true : false">
      {{ label }}
      <b-input
        :name="name"
        v-model.trim="val"
        @change="handleOnChange"
        :placeholder="placeholder"
        :class="[`${error ? 'invalid' : ''}`]"
        autocomplete="off"
        :type="type"
        :maxlength="maxlength"
        :max="max"
      />
    </float-label>
    <div
      class="fs-12 text-right mt-n3 pr-2 err-text"
      :class="name === 'email' ? 'mb-0' : 'mb-2'"
      :style="error ? 'display: block;' : 'display: none;'"
    >
      {{ error ? helperText : '' }}
    </div>
  </div>
</template>
<script>
export default {
  components: { 
    FloatLabel: () => import('./FloatLabel.vue')
    },
  name: 'CustomInput',
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  computed: {
    val: {
      get() {
        return this.inputValue;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleOnChange: function (val) {
      this.$emit('change', val)
    },
  },
  props: {
    inputValue: {
      type: [Number, String],
      default: () => ""
    },
    label: String,
    name: {
      type: String,
      default: () => ""
    },
    error: Boolean,
    helperText: String,
    placeholder: String,
    type: String,
    maxlength: {
      type: String,
      default: () => "256"
    },
    max: {
      type: String,
      default: () => ""
    },
  },
}
</script>
<style lang="scss">
input.form-control.invalid {
  border-color: red !important ;
}
.invalid {
  border-color: red !important;
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
</style>